import React from "react";
import cn from "classnames";

import "./PRIconWrapper.scss";

interface PRIconWrapperProps {
  className?: string;
}

export const PRIconWrapper = ({
  className,
  children,
}: React.PropsWithChildren<PRIconWrapperProps>) => {
  return <div className={cn(className, "pr-icon-wrapper")}>{children}</div>;
};
