import cn from "classnames";

import "./ServiceItem.scss";

interface ServiceItemProps {
  title: string;
  icon?: React.ReactNode;
  className?: string;
  onlyText?: boolean;
  onClick?: () => void;
}

export const ServiceItem = ({
  title,
  icon,
  className,
  onClick,
  onlyText,
}: ServiceItemProps) => {
  return (
    <div onClick={onClick} className={cn(className, "service-item")}>
      {!onlyText && <div className={"service-item__icon"}>{icon}</div>}
      <div className={"service-item__title"}>{title}</div>
    </div>
  );
};
