import { MdClose } from "react-icons/md";

import { ModalButton } from "../ModalButton";

import "./ModalClose.scss";

interface ModalCloseProps {
  onClick: () => void;
}

export const ModalClose = ({ onClick }: ModalCloseProps) => {
  return (
    <ModalButton onClick={onClick} className="modal-close">
      <MdClose />
    </ModalButton>
  );
};
