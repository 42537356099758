import { useNavigate } from "react-router-dom";
import cn from "classnames";

import "./Breadcrumb.scss";

export interface BreadcrumbPropsLink {
  label: string;
  value: string;
}

interface BreadcrumbProps {
  className?: string;
  links: BreadcrumbPropsLink[];
}

export const Breadcrumb = ({ links, className }: BreadcrumbProps) => {
  const navigate = useNavigate();
  return (
    <div className={cn(className, "breadcrumb")}>
      {links.map((link: BreadcrumbPropsLink, index: number) => {
        return (
          <div className="breadcrumb-wrapper" key={index}>
            <div
              onClick={() => {
                document.body.classList.remove("fixed_body");
                navigate(link.value);
              }}
              className="breadcrumb__link"
            >
              {link.label}
            </div>
            {index !== links.length - 1 && links.length !== 1 && (
              <div className="breadcrumb__divider">{`>`}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};
