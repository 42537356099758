import "./Button.scss";
import cn from "classnames";

import { FilterButton } from "./FilterButton";
import { RoundedButton } from "./RoundedButton";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  withHover?: boolean;
}

const Button = ({
  children,
  className,
  withHover,
  onClick,
  ...props
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(className, "button", { ["with-hover"]: withHover })}
      {...props}
    >
      {children}
    </button>
  );
};

Button.FilterButton = FilterButton;
Button.RoundedButton = RoundedButton;

export { Button };
