import cn from "classnames";

import "./PRFilterButton.scss";

export interface PRFilterButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

export const PRFilterButton = ({
  children,
  className,
  active,
  ...props
}: PRFilterButtonProps) => {
  return (
    <button
      {...props}
      className={cn(className, "pr-filter-button", {
        ["pr-filter-button__active"]: active,
      })}
    >
      {children}
    </button>
  );
};
