import cn from "classnames";

import "./PRRadioCircle.scss";

interface PRRadioCircleProps {
  className?: string;
  active?: boolean;
}

export const PRRadioCircle = ({ className, active }: PRRadioCircleProps) => {
  return (
    <div
      className={cn("pr-radio-circle-wrapper-comp", className, {
        ["pr-radio-cirlce-comp-active"]: active,
      })}
    >
      {active && <div className="pr-radio-circle-comp" />}
    </div>
  );
};
