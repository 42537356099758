export const generateTimeString = (workingTime: {
  monday: string[];
  tuesday: string[];
  wednesday: string[];
  thursday: string[];
  friday: string[];
  saturday: string[];
  sunday: string[];
  exceptions: string[];
}) => {
  let hoursArr = [];

  if (workingTime?.monday[0] !== undefined) {
    hoursArr.push(`ორშ: ${workingTime?.monday?.toString()}`);
  }

  if (workingTime?.tuesday[0] !== undefined) {
    hoursArr.push(` სამ: ${workingTime?.tuesday?.toString()}`);
  }

  if (workingTime?.wednesday[0] !== undefined) {
    hoursArr.push(` ოთხ: ${workingTime?.wednesday?.toString()}`);
  }

  if (workingTime?.thursday[0] !== undefined) {
    hoursArr.push(` ხუთ: ${workingTime?.thursday?.toString()}`);
  }

  if (workingTime?.friday[0] !== undefined) {
    hoursArr.push(` პარ: ${workingTime?.friday?.toString()}`);
  }

  if (workingTime?.saturday[0] !== undefined) {
    hoursArr.push(` შაბ: ${workingTime?.saturday?.toString()}`);
  }

  if (workingTime?.sunday[0] !== undefined) {
    hoursArr.push(` კვირა: ${workingTime?.sunday?.toString()}`);
  }

  if (workingTime?.exceptions[0] !== undefined) {
    hoursArr.push(` გამონაკლისი: ${workingTime?.exceptions?.toString()}`);
  }
  
  return hoursArr.toString().replace(/,/g, ' &nbsp; ');
};
