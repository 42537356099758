import parse from "html-react-parser";
import DOMPurify from "dompurify";

export const convertRawToHtml = (html: string) => {
  if (html) {
    const cleanHtml = DOMPurify.sanitize(html, {
      USE_PROFILES: { html: true },
      ADD_ATTR: ["target"],
      ADD_TAGS: ["iframe"],
    });

    return parse(cleanHtml);
  }
};
