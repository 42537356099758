import cn from "classnames";

import "./CircleIconWrapper.scss";

interface CircleIconWrapperProps {
  className?: string;
  onClick?: () => void;
}

export const CircleIconWrapper = ({
  className,
  children,
  onClick,
}: React.PropsWithChildren<CircleIconWrapperProps>) => {
  return (
    <div onClick={onClick} className={cn(className, "circle-icon-wrapper")}>
      {children}
    </div>
  );
};
