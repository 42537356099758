import { NavigationMobile } from "./NavigationMobile";
import { NavigationDesktop } from "./NavigationDesktop";

import { useWindowSize } from "rooks";

export const Navigation = () => {
  const { innerWidth } = useWindowSize();

  return (
    <div>
      {innerWidth && innerWidth <= 1200 ? (
        <NavigationMobile />
      ) : (
        <NavigationDesktop />
      )}
    </div>
  );
};
