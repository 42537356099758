import cn from "classnames";

import "./Container.scss";

export interface ContainerProps {
  className?: string;
}

export const Container = ({
  children,
  className,
}: React.PropsWithChildren<ContainerProps>) => {
  return <div className={cn(className, "container")}>{children}</div>;
};
