import cn from "classnames";
import { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

import { TextInput, TextInputProps } from "../TextInput";

import "./PasswordInput.scss";

interface PasswordInputProps extends TextInputProps {
  passwordInputClassName?: string;
}

export function PasswordInput({
  passwordInputClassName,
  handleValue,
  errorMessage,
  ...props
}: PasswordInputProps) {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className={cn(passwordInputClassName, "password-wrapper")}>
      <TextInput
        {...props}
        type={visible ? "text" : "password"}
        wrapperClassName={cn(props.wrapperClassName, "password-input")}
        handleValue={handleValue}
        password
      />
      {errorMessage && (
        <div className="text-input-error-message mb-40">{errorMessage}</div>
      )}
    </div>
  );
}
