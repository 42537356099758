import cn from "classnames";
import { Button } from "components/Button";

import "./TabsButton.scss";

interface TabsButtonProps {
  onClick?: () => void;
  isActive?: boolean;
  children?: React.ReactNode | JSX.Element;
  className?: string;
  disabled?: boolean;
}

export function TabsButton({
  onClick,
  isActive,
  children,
  className,
  disabled,
}: TabsButtonProps) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={cn(
        className,
        `tabs__button ${isActive && "tabs__button-active"}`
      )}
    >
      {children}
    </Button>
  );
}
