import { Outlet } from "react-router-dom";

import { Footer, Navigation } from "components";

import "./PublicLayout.scss";

export const PublicLayout = () => {
  return (
    <>
      <Navigation />
      <div className="public-outlet-wrapper">
        <Outlet />
      </div>
      <Footer className="public-footer" />
    </>
  );
};
