import cn from "classnames";
import { useEffect, useState } from "react";

import { PaginationItem } from "./PaginationItem";
import { rangeWithDots } from "./utils/RangeWithDots";

import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos
} from "react-icons/md";
import "./Pagination.scss";

interface PaginationProps {
  className?: string;
  pageSize: number;
  total?: number;
  currentPage: number;
  onChange: any;
  maxPages?: number;
}

export const Pagination = ({
  className,
  total,
  maxPages,
  pageSize,
  currentPage,
  onChange
}: PaginationProps) => {
  const [pages, setPages] = useState<Array<number | string | any>>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    if (total) {
      return setTotalPages(Math.ceil(total / pageSize));
    } else if (maxPages) {
      return setTotalPages(maxPages);
    }
  }, [maxPages, total]);

  useEffect(() => {
    const nextPages = rangeWithDots(currentPage, totalPages);
    setPages(nextPages);
    window.scrollTo(0, 0);
  }, [currentPage, totalPages]);

  return (
    <>
      {totalPages > 1 && (
        <div className={cn(className, "pagination")}>
          <div className="pagination__content">
            <MdOutlineArrowBackIosNew
              className="pagination-arrow"
              onClick={() => currentPage !== 1 && onChange(currentPage - 1)}
            />
            {pages.map((page: number, i) => {
              if (typeof page !== "number") {
                return <PaginationItem key={i}>{page}</PaginationItem>;
              } else {
                return (
                  <PaginationItem
                    key={i}
                    active={currentPage === page}
                    onClick={() => onChange(page)}
                  >
                    {page}
                  </PaginationItem>
                );
              }
            })}
            <MdOutlineArrowForwardIos
              className="pagination-arrow"
              onClick={() =>
                currentPage !== totalPages && onChange(currentPage + 1)
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

Pagination.defaultProps = {
  currentPage: 1
};
