import { AiFillHome, AiFillSetting } from "react-icons/ai";
import { IoMdNotifications } from "react-icons/io";
import { MdLogout, MdPayment } from "react-icons/md";
import { RiCalendarCheckFill, RiFlaskFill, RiHome4Fill } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import { PRSidebarButton } from "./PRSidebarButton";
import { PRSidebarLink } from "./PRSidebarLink";

import "./PRSidebarDesktop.scss";
import { LocalStorage } from "config/keys";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "providers/AuthProvider/context/AuthContext";
import { useContext } from "react";
import { BsExclamationOctagonFill } from "react-icons/bs";

export function PRSidebarDesktop() {
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);
  return (
    <div className="sidebar-desktop">
      <div className="sidebar-desktop__body">
        {/* <PRSidebarLink to="/pr-personal-page" icon={<AiFillHome />}>
          <FormattedMessage
            id="private.sidebar.personal-page"
            defaultMessage="_personal-page_"
          />
        </PRSidebarLink> */}

        <PRSidebarLink
          to="/pr-visit-doctor/current"
          icon={<RiCalendarCheckFill />}
        >
          <FormattedMessage
            id="private.sidebar.visit-doctor"
            defaultMessage="_visit-doctor_"
          />
        </PRSidebarLink>

        {/* <PRSidebarLink to="pr-analysis" icon={<RiFlaskFill />}>
          <FormattedMessage
            id="private.sidebar.analysis"
            defaultMessage="_analysis_"
          />
        </PRSidebarLink> */}

        {/* <PRSidebarLink
          to="pr-notifications"
          icon={
            <div className="sidebar-desktop__notification">
              <IoMdNotifications />
              <span className="sidebar-desktop__notification-badge">2</span>
            </div>
          }
        >
          <FormattedMessage
            id="private.sidebar.notifications"
            defaultMessage="_notifications_"
          />
        </PRSidebarLink> */}

        {/* <PRSidebarLink to="pr-payments" icon={<MdPayment />}>
          <FormattedMessage
            id="private.sidebar.payments"
            defaultMessage="_payments_"
          />
        </PRSidebarLink> */}

        {/* <PRSidebarLink
          to="pr-unsatisfaction"
          icon={<BsExclamationOctagonFill />}
        >
          <FormattedMessage id="private.unsatisfaction.header" />
        </PRSidebarLink> */}
      </div>
      <div className="sidebar-desktop__footer">
        <PRSidebarLink to="pr-settings" icon={<AiFillSetting />}>
          <FormattedMessage
            id="private.sidebar.settings"
            defaultMessage="_settings_"
          />
        </PRSidebarLink>
        <PRSidebarButton
          icon={<MdLogout />}
          className="sidebar-desktop__footer--button"
          onClick={() => {
            localStorage.removeItem(LocalStorage.AUTH_TOKEN);
            setToken(null);
            navigate("/");
          }}
        >
          <FormattedMessage
            id="private.sidebar.logout"
            defaultMessage="_logout_"
          />
        </PRSidebarButton>
      </div>
    </div>
  );
}
