import { NavigationMobile } from "components";
import { Outlet } from "react-router-dom";
import { useWindowSize } from "rooks";
import { AuthHeader } from "../../views/auth/AuthHeader";

import "./AuthLayout.scss";

export const AuthLayout = () => {
  const { innerWidth } = useWindowSize();
  return (
    <div className="auth-layout">
      {innerWidth && innerWidth <= 1100 ? (
        <NavigationMobile hideSearch />
      ) : (
        <AuthHeader className="auth-layout__header" />
      )}
      <Outlet />
    </div>
  );
};
