import { Wrapper } from "@googlemaps/react-wrapper";
import { Loading } from "components";
import { PropsWithChildren } from "react";

interface MapProviderProps {}

const render = () => {
  return <Loading />;
};

export function MapProvider({ children }: PropsWithChildren<MapProviderProps>) {
  return (
    <Wrapper
      render={render}
      apiKey={"AIzaSyBkTVw6szQS-0hIaKTlFtFFXZi6SKcifas" || ""}
    >
      {children}
    </Wrapper>
  );
}
