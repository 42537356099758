import { useEffect, useState } from "react";

export const useScrollStopedIndicator = () => {
  const [scrolling, setScrolling] = useState<boolean>(false);

  useEffect(() => {
    let isScrolling: any;

    const scrollIndicator = () => {
      setScrolling(true);
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(function () {
        // Run the callback
        setScrolling(false);
      }, 600);
    };
    window.addEventListener("scroll", scrollIndicator);

    return () => {
      window.removeEventListener("scroll", scrollIndicator);
    };
  });

  return { scrolling };
};
