import cn from "classnames";

import { NavLink, NavLinkProps } from "react-router-dom";

import "./PRSidebarLink.scss";

interface PRSidebarLinkProps extends NavLinkProps {
  icon?: React.ReactNode | JSX.Element;
  children: React.ReactNode | JSX.Element;
  className?: string;
}

export const PRSidebarLink = ({
  icon,
  className,
  children,
  ...props
}: PRSidebarLinkProps) => {
  return (
    <NavLink className={cn(className, "pr-sidebar-link")} {...props}>
      <span className="pr-sidebar-link__icon">{icon}</span>
      <p className="pr-sidebar-link__text"> {children}</p>
    </NavLink>
  );
};
