import { createContext } from "react";

export enum Enum_HealthCheck_Gender {
  male = "male",
  female = "female"
}

export enum Enum_HealthCheck_title {
  basis = "ბაზისური",
  standart = "სტანდარტული",
  standardExtra = "სტანდარტული გაფართოებული",
  full = "სრული"
}
export type HealthCheckFormType = {
  gender?: string;
  age?: string;
  email?: string;
  title: string;
  name?: string;
  phone: string;
};

export type HealthCheckContextType = {
  healthCheckForm: HealthCheckFormType;
  setHealthCheckForm: any;
};

export const HealthCheckContext = createContext<HealthCheckContextType>({
  healthCheckForm: {
    title: "",
    phone: ""
  },
  setHealthCheckForm: () => {}
});
