import cn from "classnames";
import { PropsWithChildren } from "react";
import { PromotionLabel } from "./PromotionLabel";

import "./Label.scss";

export interface LabelProps {
  className?: string;
}

const Label = ({ children, className }: PropsWithChildren<LabelProps>) => {
  return <div className={cn(className, "label")}>{children}</div>;
};

Label.PromotionLabel = PromotionLabel;

export { Label };
