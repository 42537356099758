import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  keyword: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    saveKeyword: (
      state: typeof initialState,
      action: PayloadAction<string>
    ) => {
      state.keyword = action.payload;
    },
  },
});

export const { saveKeyword } = searchSlice.actions;

export default searchSlice.reducer;
