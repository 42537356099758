import { FormattedMessage } from "react-intl";
import { ContactInfo } from "./ContactInfo";
import { FooterLink } from "./FooterLink";

import "./FooterRows.scss";
import { useFooterNavigation } from "hooks/useFooterNavigation";

export const FooterRows = () => {
  const { footerNavigation: footer1 } = useFooterNavigation(1);
  const { footerNavigation: footer2 } = useFooterNavigation(2);
  const { footerNavigation: footer3 } = useFooterNavigation(3);
  
  return (
    <div className="rows">
      <div className="footer-links">
        {footer1?.map((link: any) => {
          return (
            <FooterLink key={link?.id} to={link?.value}>
              {/* <FormattedMessage
                id="footer.link.main"
                defaultMessage="_footer-main_"
              /> */}
              {link?.name}
            </FooterLink>
          );
        })}
      </div>
      <div className="footer-links">
        {footer2?.map((link: any) => {
          return (
            <FooterLink key={link?.id} to={link?.value}>
              {link?.name}
            </FooterLink>
          );
        })}
      </div>
      <div className="footer-links">
        {footer3?.map((link: any) => {
          return (
            <FooterLink key={link?.id} to={link?.value}>
              {link?.name}
            </FooterLink>
          );
        })}
      </div>
      <ContactInfo />
    </div>
  );
};
