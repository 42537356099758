import cn from "classnames";

import "./IconWithBorder.scss";

interface IconWithBorderProps {
  children: string | JSX.Element;
  size?: "small" | "medium" | "large";
  mode?: "dark" | "light";
  className?: string;
}

export const IconWithBorder = ({
  children,
  size,
  mode,
  className,
}: IconWithBorderProps) => {
  return (
    <div
      className={cn(className, "icon-with-border", {
        ["small"]: size === "small",
        ["medium"]: size === "medium",
        ["large"]: size === "large",
        ["light"]: mode === "light",
        ["dark"]: mode === "dark",
      })}
    >
      {children}
    </div>
  );
};

IconWithBorder.defaultProps = {
  size: "small",
  mode: "light",
};
