import cn from "classnames";
import "./TransparentCard.scss";

interface TransparentCardProps {
  className?: string;
  onClick?: () => void;
}

export const TransparentCard = ({
  children,
  className,
  onClick,
}: React.PropsWithChildren<TransparentCardProps>) => {
  return (
    <div onClick={onClick} className={cn(className, "transparent-card")}>
      <span className={cn(className, "transparent-card__text")}>
        {children}
      </span>
    </div>
  );
};
