export const rangeWithDots = (index: number, count: number) => {
  let range: (number | string)[] = [];
  let end = count;
  let start = 1;
  if (count > 5) {
    if (index >= 5) {
      range = [1, "..."];

      if (index <= count - 4) {
        start = index - 2;
        end = index + 2;
      } else {
        range = [1, "..."];
        start = count - 4;
      }
    } else {
      end = 5;
    }
  }

  for (let i = start; i <= end; i++) {
    range.push(i);
    if (i === end && end < count) {
      range.push("...");
      range.push(count);
    }
  }
  return range;
};
