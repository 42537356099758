import cn from "classnames";
// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { ArrowContainer, Popover } from "react-tiny-popover";

import "./NavItem.scss";

interface NavItemProps {
  to: string;
  target: string;
  className?: string;
  expand?: boolean;
  onClick?: () => void;
  hasChildren?: boolean;
  popoverContent?: JSX.Element[];
}

export const NavItem = ({
  to,
  children,
  className,
  hasChildren,
  popoverContent,
  target
}: React.PropsWithChildren<NavItemProps>) => {
  const location = useLocation();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [contentActive, setContentActive] = useState<boolean>(isPopoverOpen);
  const [parent, setParent] = useState<HTMLElement>(document.body);
  const [uniqueId, setUniqueId] = useState<string>("");

  const isActiveCustom = () => {
    return location?.pathname?.startsWith(to);
  };

  useEffect(() => {
    const generatedId = `navlink-${to}-${hasChildren ? 0 : 1}`;
    setUniqueId(generatedId);

    const parentElement = document.getElementById(generatedId);
    if (parentElement) {
      setParent(parentElement);
    }
  }, [to]);

  return (
    <div
      onMouseEnter={() => hasChildren && setIsPopoverOpen(true)}
      onMouseLeave={() =>
        hasChildren &&
        !contentActive &&
        setTimeout(() => setIsPopoverOpen(false), 500)
      }
    >
      <Popover
        parentElement={parent}
        isOpen={isPopoverOpen}
        positions={["bottom", "top", "left", "right"]}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={""}
            arrowSize={18}
            className="popover-arrow-container"
            arrowClassName="navitem-popover-arrow"
          >
            <div
              className="navitem-popover-content"
              onMouseEnter={() => setContentActive(true)}
              onMouseLeave={() =>
                setTimeout(() => setIsPopoverOpen(false), 500)
              }
            >
              {popoverContent?.map((content, i) => (
                <div key={i}>{content}</div>
              ))}
            </div>
          </ArrowContainer>
        )}
        reposition={false}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div className="navitem-wrapper">
          <NavLink
            id={`${uniqueId}`}
            className={({ isActive }) =>
              isActive
                ? isActiveCustom()
                  ? `active ${cn("navitem", className)}`
                  : cn("navitem", className)
                : cn("navitem", className)
            }
            to={to}
            target={target}
          >
            {children}
          </NavLink>
          {hasChildren && <MdKeyboardArrowDown className="navitem-expand" />}
        </div>
      </Popover>
    </div>
  );
};
