import { useQuery } from "react-query";
import { publicRequest } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const useMain = () => {
  const { language } = useSelector((state: RootState) => state.global);

  const { data: mainPageData, isLoading, isSuccess, isFetching } = useQuery(
    [language, "mainPageData"],
    () =>
      publicRequest({
        url: `/api/pages/home/${language}`,
        method: "get"
      })
  );
  return {
    main: mainPageData?.data,
    slides: mainPageData?.data?.slides,
    mobileSlides: mainPageData?.data?.mobile_slides,
    services: mainPageData?.data?.services,
    labs: mainPageData?.data?.labs,
    isLoading,
    isSuccess,
    isFetching
  };
};
