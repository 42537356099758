import { AiFillLinkedin, AiFillYoutube } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";

import { IconWithBorder } from "components/IconWithBorder";

import "./FooterBottom.scss";
import { FormattedMessage } from "react-intl";
import { LanguageChanger } from "components/LanguageChanger";

export const FooterBottom = () => {
  return (
    <div className="footer-bottom-border">
      <div className="footer-bottom-wrapper">
        <div className="footer-bottom-item">
          <div className="social-media">
            <div>
              <span>
                <FormattedMessage
                  id="footer.social-networks"
                  defaultMessage="_social-networks_"
                />
              </span>
            </div>
            <div className="social-media__icon-wrapper">
              <a
                className="social-media__icon"
                href="https://www.linkedin.com/company/ავერსის-კლინიკა-•-aversi-clinic/"
                target="_blank"
                aria-label="Linkedin"
                rel="noreferrer"
              >
                <IconWithBorder size="small" mode="dark">
                  <AiFillLinkedin />
                </IconWithBorder>
              </a>
              <a
                className="social-media__icon"
                href="https://www.youtube.com/@AversiGeClinic"
                target="_blank"
                rel="noreferrer"
                aria-label="Youtube"
              >
                <IconWithBorder size="small" mode="dark">
                  <AiFillYoutube />
                </IconWithBorder>
              </a>
              <a
                className="social-media__icon"
                href="https://www.facebook.com/AversiClinic"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <IconWithBorder size="small" mode="dark">
                  <FaFacebookF />
                </IconWithBorder>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom-item">
          <span>
            <FormattedMessage
              id="footer.all-rights-reserved"
              defaultMessage="_all-rigths-reserved_"
            />
            . © {new Date().getFullYear()}
          </span>
        </div>
        <div className="footer-bottom-item">
          <span>
            Created with love by&nbsp;
            <a href="https://www.facebook.com/growthhunterstbs/">
              Growth Hunters
            </a>
          </span>
        </div>
        {/* <div>
          <LanguageChanger position="top" className='footerLanguageChanger'/>
        </div> */}
      </div>
    </div>
  );
};
