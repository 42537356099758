import { ScrollToTop } from "./common/ScrollToTop";

import { PrivateRoutes } from "./views/private/PrivateRoutes";
import { AuthRoutes } from "./views/auth/AuthRoutes";
import { PublicRoutes } from "./views/public/PublicRoutes";

function App() {
  return (
    <>
      <PrivateRoutes />
      <AuthRoutes />
      <PublicRoutes />
      <ScrollToTop />
    </>
  );
}

export default App;
