import { useEffect, useState } from "react";

export const useScrolledIndicator = () => {
  const [scrolled, setScrolled] = useState<boolean>();

  useEffect(() => {
    const handleScroll = () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scrolled) {
        setScrolled(scrollCheck);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return { scrolled };
};
