import cn from "classnames";

import "./PaginationItem.scss";

interface PaginationItemProps {
  active?: boolean;
  className?: string;
  onClick?: () => void;
}

export const PaginationItem = ({
  children,
  className,
  active,
  onClick,
}: React.PropsWithChildren<PaginationItemProps>) => {
  return (
    <div
      onClick={onClick}
      className={cn(className, "pagination-item", {
        ["active"]: active,
      })}
    >
      {children}
    </div>
  );
};
