export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: "24px",
    borderColor: state.isFocused ? "#303134" : "#D2D2D2",
    outline: "#303134",
    boxShadow: "none",
    fontSize: "1rem",
    fontWeight: 400,
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "20px",
   
   

    "&:hover": {
      borderColor: "#303134",
    },
    "&:focused": {
      borderColor: "#303134",
    },
   
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: "24px",

    borderColor: "#D2D2D2",
    overflow: "hidden",
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#EF3125" : "#979899",
    backgroundColor: state.isSelected ? "#F8F9FC" : "transperant",
    "&:hover": {
      color: "#EF3125",
      backgroundColor: "#F8F9FC",
    },
    paddingTop: "12px",
    paddingBottom: "12px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};
