import { useState } from "react";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState
} from "react-accessible-accordion";

import cn from "classnames";
import { convertRawToHtml } from "utils";
import { useLaboratoryAnalysis } from "views/public/LaboratoryAnalysis/hooks";
import { Link } from "react-router-dom";

import "./AccordionComponentItem.scss";

interface AccordionComponentItemProps {
  question: string | JSX.Element;
  answer: string | JSX.Element;
  icon?: string;
  categoryId?: string;
  searchValue?: string;
}

export function AccordionComponentItem({
  icon,
  question,
  answer,
  categoryId,
  searchValue
}: AccordionComponentItemProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { laboratoryAnalsysis } = useLaboratoryAnalysis(
    categoryId,
    searchValue
  );

  return (
    <AccordionItem
      className={cn("accordion__item", { ["expanded"]: isExpanded })}
    >
      <AccordionItemHeading className="accordion__item-heading">
        <AccordionItemButton className="accordion__item-heading-button">
          {icon}
          <div className="accordion__item-heading-button-question">
            {question}
          </div>
          <AccordionItemState>
            {({ expanded }) => {
              expanded && setIsExpanded(expanded);
              return null;
            }}
          </AccordionItemState>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="accordion__item-panel">
        <div className="accordion__item-panel-text">
          {typeof answer === "string" ? convertRawToHtml(answer) : answer}
          {categoryId && (
            <ul>
              {laboratoryAnalsysis?.map((analysis: any) => {
                return (
                  <li key={analysis?.id}>
                    <Link to={`/laboratory-analysis/${analysis?.id || ""}`}>
                      {analysis?.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}
