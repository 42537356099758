import { useQuery } from "react-query";
import { publicRequest } from "utils";

import { languages_enum } from "redux/actions/globalSlice";

import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const useHeaderNavigation = () => {
  const { language } = useSelector((state: RootState) => state.global);

  const { data: headerNavigation } = useQuery(
    ["header-navigation", language],
    () =>
      publicRequest({
        url: `/api/menus/itemsbyposition/header/${language}`,
        method: "get",
      }),
    { staleTime: 0, cacheTime: 0 }
  );

  return { headerNavigation: headerNavigation?.data };
};
