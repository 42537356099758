import { NavLink } from "react-router-dom";
import "./FooterLink.scss";

interface FooterLinkProps {
  to: string;
}

export const FooterLink = ({
  to,
  children,
}: React.PropsWithChildren<FooterLinkProps>) => {
  return (
    <NavLink to={to} className="footer-link">
      {children}
    </NavLink>
  );
};
