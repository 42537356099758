import cn from "classnames";
import "./PageTitle.scss";

export interface PageTitleProps {
  className?: string;
}

export const PageTitle = ({
  children,
  className,
}: React.PropsWithChildren<PageTitleProps>) => {
  return <h1 className={cn(className, "page-title")}>{children}</h1>;
};
