import { InputHTMLAttributes } from "react";
import "./Radio.scss";
interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {}

export function Radio({ children, ...props }: RadioProps) {
  return (
    <div className="radio">
      <input type="radio" {...props} />
      <label htmlFor={props?.id} className="radio-label">
        {children}
      </label>
    </div>
  );
}
