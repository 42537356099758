import "./SearchResultsItem.scss";

interface SearchResultsItemProps {
  image: string;
  content: string | JSX.Element;
  category: string;
  onClick?: () => void;
}

export function SearchResultsItem({
  image,
  content,
  category,
  onClick
}: SearchResultsItemProps) {
  return (
    <div onClick={onClick} className="search-results-item">
      <div className="search-results-item__image">
        <img src={image} alt="search-result" loading="lazy" />
      </div>

      <div className="search-results-item__info">
        <div className="search-results-item__info-content">{content}</div>
        <div className="search-results-item__info-category">{category}</div>
      </div>
    </div>
  );
}
