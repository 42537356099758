import { useQuery } from "react-query";
import { publicRequest } from "utils";

import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const useFooterNavigation = (menu_id: number) => {
  const { language } = useSelector((state: RootState) => state.global);

  const { data: footerNavigation } = useQuery(
    ["footer-navigation", menu_id, language],
    () =>
      publicRequest({
        url: `/api/menus/itemsbyposition/footer%20${menu_id}/${language}`,
        method: "get"
      }),
    { staleTime: 0, cacheTime: 0 }
  );

  return { footerNavigation: footerNavigation?.data };
};
