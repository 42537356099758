import cn from "classnames";

import "./PRSidebarButton.scss";

interface PRSidebarButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode | JSX.Element;
}

export const PRSidebarButton = ({
  className,
  children,
  icon,
  ...props
}: PRSidebarButtonProps) => {
  return (
    <button className={cn(className, "pr-sidebar-button")} {...props}>
      <span className="pr-sidebar-button__icon">{icon}</span>
      {children}
    </button>
  );
};
