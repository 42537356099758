import cn from "classnames";

import "./PRTextButton.scss";

export interface PRTextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const PRTextButton = ({
  className,
  children,
  ...props
}: PRTextButtonProps) => {
  return (
    <button {...props} className={cn("pr-text-button", className)}>
      {children}
    </button>
  );
};
