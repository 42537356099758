import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { Loading } from "components";
import { PublicLayout } from "containers";
// import { useGlobalChat } from "hooks";

const Services = React.lazy(() => import("views/public/Services"));
const Service = React.lazy(() => import("views/public/Service"));
const Doctors = React.lazy(() => import("views/public/Doctors"));
const Main = React.lazy(() => import("views/public/Main"));
const Branches = React.lazy(() => import("views/public/Branches"));
const Branch = React.lazy(() => import("views/public/Branch"));
const Laboratory = React.lazy(() => import("views/public/Laboratory"));
const News = React.lazy(() => import("views/public/News"));
const Blog = React.lazy(() => import("views/public/Blog"));
const AboutUs = React.lazy(() => import("views/public/AboutUs"));
const Promotions = React.lazy(() => import("views/public/Promotions"));
const Gallery = React.lazy(() => import("views/public/Gallery"));
const Vacancies = React.lazy(() => import("views/public/Vacancies"));
const Vacancy = React.lazy(() => import("views/public/Vacancy"));
const AfterGraduate = React.lazy(() => import("views/public/AfterGraduate"));
const CovidQuestions = React.lazy(() => import("views/public/CovidQuestions"));
const TermsAndConditions = React.lazy(
  () => import("views/public/TermsAndConditions")
);
const SearchResults = React.lazy(() => import("views/public/SearchResults"));
const Contact = React.lazy(() => import("views/public/Contact"));
const ContactAudit = React.lazy(() => import("views/public/ContactAudit"));
const Partners = React.lazy(() => import("views/public/Partners"));
const HealthCheck = React.lazy(() => import("views/public/HealthCheck"));
// const MedicalTourism = React.lazy(() => import("views/public/MedicalTourism"));
const HealthCheckDetail = React.lazy(
  () => import("views/public/HealthCheckDetail")
);
const ResponseMessagePage = React.lazy(
  () => import("components/ResponseMessagePage")
);
const LaboratoryAnalysis = React.lazy(
  () => import("views/public/LaboratoryAnalysis")
);
const NotFound = React.lazy(() => import("views/public/404"));

export function PublicRoutes() {
  // useGlobalChat();

  const location = useLocation();
  const authStep =
    location?.pathname?.includes("/login") ||
    location?.pathname?.includes("/registration") ||
    location?.pathname?.includes("/reset-password") ||
    location?.pathname?.includes("/complete-reset-password-initial");

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path={"/"} element={<PublicLayout />}>
          <Route index element={<Main />} />
          <Route path={"services"} element={<Services />} />
          <Route path={"services/:id"} element={<Service />} />
          <Route path={"doctors"} element={<Doctors />} />
          <Route path={"doctors/:page/*"} element={<Doctors />} />
          <Route path={"branches/"} element={<Branches />} />
          <Route path={"branches/:page/*"} element={<Branches />} />
          <Route path={"branches/:page/branch/:id"} element={<Branch />} />
          <Route path={"news"} element={<News />} />
          <Route path={"news/:page/*"} element={<News />} />
          <Route path={"blog"} element={<Blog />} />
          <Route path={"blog/:page/*"} element={<Blog />} />
          <Route path={"about-us"} element={<AboutUs />} />
          <Route path={"promotions/"} element={<Promotions />} />
          <Route path={"promotions/:page/*"} element={<Promotions />} />
          <Route path={"gallery/"} element={<Gallery />} />
          <Route path={"gallery/:page/*"} element={<Gallery />} />
          <Route path={"vacancies"} element={<Vacancies />} />
          <Route path={"vacancies/:id/:slug"} element={<Vacancy />} />
          <Route path={"partners"} element={<Partners />} />
          <Route path={"health-check"} element={<HealthCheck />} />
          <Route path={"health-check/:id"} element={<HealthCheckDetail />} />
          <Route
            path={"health-check/:id/:status/:service"}
            element={<ResponseMessagePage />}
          />
          <Route path={"after-graduate"} element={<AfterGraduate />} />
          <Route path={"covid"} element={<CovidQuestions />} />
          <Route path={"terms"} element={<TermsAndConditions />} />
          {/* <Route path={"medical-tourism"} element={<MedicalTourism />} /> */}
          <Route path={"search-results/"} element={<SearchResults />} />
          <Route path={"search-results/:page"} element={<SearchResults />} />
          <Route path={"contact"} element={<Contact />} />
          <Route path={"contact-audit"} element={<ContactAudit />} />
          <Route path={"laboratory/*"} element={<Laboratory />} />
          <Route
            path={"laboratory-analysis/:id"}
            element={<LaboratoryAnalysis />}
          />
          {!location?.pathname?.includes("pr-") && !authStep && (
            <Route path={"/*"} element={<NotFound />} />
          )}
        </Route>
      </Routes>
    </Suspense>
  );
}
