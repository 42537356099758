import cn from "classnames";

import { Button, ButtonProps } from "components/Button";

import "./FilterButton.scss";

export interface FilterButtonProps extends ButtonProps {
  active?: boolean;
}

export const FilterButton = ({
  className,
  children,
  active,
  ...props
}: FilterButtonProps) => {
  return (
    <Button
      {...props}
      className={cn(className, "filter-button", {
        ["filter-button__active"]: active,
      })}
    >
      {children}
    </Button>
  );
};

FilterButton.defaultProps = {
  active: false,
};
