export const NavigateToFirstPage = (value: string) => {
  const routesWithPagination = [
    "/doctors",
    "/branches",
    "/news",
    "/gallery",
    "/blog",
    "/promotions",
  ];

  return routesWithPagination.includes(value);
};
