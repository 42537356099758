export const getHighlightedText = (text: string, keyword?: string) => {
  const parts = text.split(new RegExp(`(${keyword})`, "gi"));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === keyword?.toLowerCase()
              ? {
                  backgroundColor: "#F8DEC2"
                }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};
