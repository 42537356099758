import cn from "classnames";
import "./Title.scss";

type TitleProps = {
  className?: string;
};

export const Title = ({
  children,
  className,
}: React.PropsWithChildren<TitleProps>) => {
  return <h1 className={cn(className, "title")}>{children}</h1>;
};
