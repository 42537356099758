import React from "react";
import { CircleIconWrapper } from "components/CircleIconWrapper";

import "./IconItem.scss";
import { convertIconToReactNode } from "utils/convertIconToReactNode";

interface IconItemProps {
  text: string;
  icon: string;
  className?: string;
}

export const IconItem = ({
  text,
  icon,
  children,
  className,
}: React.PropsWithChildren<IconItemProps>) => {
  return (
    <div className="icon-item">
      <CircleIconWrapper>{convertIconToReactNode(icon)}</CircleIconWrapper>
      <span className="icon-item__number"> {children} </span>
      <span className="icon-item__text">{text}</span>
    </div>
  );
};
