import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { saveKeyword } from "redux/actions/searchSlice/searchSlice";
import { RootState } from "redux/store";
import { publicRequest } from "utils";

export interface useSearchInputArgs {
  currentPage?: number;
  searchTerm: string;
  PAGE_SIZE?: number;
}

export const useSearchInput = ({
  currentPage = 0,
  searchTerm,
  PAGE_SIZE = 100,
}: useSearchInputArgs) => {
  const { language } = useSelector((state: RootState) => state.global);
  const dispatch = useDispatch();

  const { data: results, isLoading } = useQuery(
    [language, currentPage, searchTerm, "searchInput"],
    () =>
      publicRequest({
        url: `/api/search/${language}?per_page=${PAGE_SIZE}&page=${currentPage}&search_term=${
          searchTerm !== "" && searchTerm
        }`,
        method: "get",
      })
  );

  if (results) {
    dispatch(saveKeyword(searchTerm));
  }

  return {
    searchResults: results?.data?.data,
    isLoading,
    maxPages: results?.data?.max_pages,
    PAGE_SIZE,
  };
};
