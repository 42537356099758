import cn from "classnames";
import { forwardRef } from "react";

import { ButtonProps } from "components/Button";

import "./RoundedButton.scss";

export interface RoundedButtonProps extends ButtonProps {
  children: string | JSX.Element;
  className?: string;
}

export const RoundedButton = forwardRef(
  (
    { children, className, ...props }: RoundedButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button {...props} ref={ref} aria-label='Action' className={cn(className, "rounded-button")}>
        {children}
      </button>
    );
  }
);
