import { Provider } from "react-redux";
import { store } from "redux/store";

import { PropsWithChildren } from "react";

interface ReduxProviderProps {}

export const ReduxProvider = ({
  children,
}: PropsWithChildren<ReduxProviderProps>) => {
  return <Provider store={store}>{children}</Provider>;
};
