import cn from "classnames";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import ka from "date-fns/locale/ka";
import { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./RangePicker.scss";

interface RangePickerProps extends ReactDatePickerProps {}

export const RangePicker = ({ className, ...props }: RangePickerProps) => {
  registerLocale("ka", ka);
  return (
    <DatePicker
      locale={ka}
      popperClassName="range-picker-poper"
      calendarClassName="range-picker-poper"
      wrapperClassName={cn("range-picker", className)}
      {...props}
    />
  );
};

RangePicker.defaultProps = {
  selectsRange: true,
};
