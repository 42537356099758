import { Button } from "components";
import { AuthFormWrapper } from "views/auth/AuthFormWrapper";
import { useNavigate } from "react-router-dom";
import { PasswordInput } from "components";

import "./CompleteResetPassword.scss";
import { privateRequest } from "utils/privateRequest";
import { useMutation } from "react-query";
import { useState } from "react";

export function CompleteResetPassword({
  data
}: {
  data: { email: string; reset_code: string };
}) {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordValidationLen, setPasswordValidationLen] = useState<boolean> (false)
  const [passwordConfirmValidationLen, setPasswordConfirmValidationLen] = useState<boolean> (false)
  const [passwordConfirmValidation, setPasswordConfirmValidation] = useState<boolean> (false)

  var myHeaders = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json"
  };
  const handleResetPassword = useMutation(
    () =>
      privateRequest({
        url: `/api/reset/password?email=${data?.email}&reset_code=${data?.reset_code}&password=${password}&password_confirmation=${passwordConfirmation}`,
        method: "post",
        headers: myHeaders
      }),
    {
      onSuccess: (response: any) => {
        if (!response?.data?.status) {
          setPasswordError(response?.data?.message);
        } else {
          setPasswordError("");
          navigate("/login");
        }
      },
      onError: (response: any) => {
        setPasswordError(
          response?.response?.data?.message === "Invalid reset code"
            ? "კოდი არასწორია"
            : ''
        );
      }
    }
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if(password.length >= 8 && passwordConfirmation === password){
      handleResetPassword.mutate();
    }

    if(password.length < 8){
  setPasswordValidationLen(true)
    } else setPasswordValidationLen(false)

    if(password.length > 0 && passwordConfirmation !== password){
      setPasswordConfirmValidation(true)
    }else setPasswordConfirmValidation(false)

    if(passwordConfirmation.length === 0 && password.length > 7){
      setPasswordConfirmValidationLen(true)
    }else setPasswordConfirmValidationLen(false)
  };
  return (
    <AuthFormWrapper
      headerText="შეიყვანეთ ახალი პაროლი"
      footerText="რეგისტრაცია"
      question="არ გაქვთ ანგარიში?"
      link="/"
    >
      <form onSubmit={handleSubmit} className="complete-reset-password">
        <PasswordInput
          className="complete-reset-password__input"
          placeholder="ახალი პაროლი"
          handleValue={(value) => setPassword(value)}
          errorMessage={passwordValidationLen ? "პაროლი უნდა შედგებოდეს სულ მცირე 8 სიმბოლოსგან": ''}
        />
        {/*{passwordValidationLen && <p style={{color: 'red'}}>{'პაროლი უნდა შედგებოდეს სულ მცირე 6 სიმბოლოსგან'}</p>}*/}
        <PasswordInput
          className="complete-reset-password__input"
          placeholder="გაიმეორეთ ახალი პაროლი"
          handleValue={(value) => setPasswordConfirmation(value)}
          errorMessage={passwordConfirmValidationLen ? 'გთხოვთ გაიმეოროთ პაროლი' : ''}
        />
        {passwordConfirmValidation && <p style={{color: 'red', marginTop: '10px'}}>{'პაროლები არ ემთხვევა ერთმანეთს'}</p>}
        <Button type="submit" className="new-password-button">
          პაროლის აღდგენა
        </Button>
      </form>
    </AuthFormWrapper>
  );
}
