import { createContext } from "react";

export enum Enum_ReserveFor {
  Me = "Me",
  Other = "Other"
}

export type ReservePatientForm = {
  pid: string;
  firstName: string;
  lastname: string;
  phone: string;
  dateOfBirth: string;
  gender?: "",
  citizenship?: ""
};

export type reserveFormType = {
  ReserveFor: string;
  ReservePatient?: ReservePatientForm;
  ReserveBy: string;
  ReserveBranch: string;
  ReserveService: number;
  ReserveServiceDetailed: string;
  ReserveDoctor: string;
  ReserveTime: string;
  ReserveServiceSourceId: string;
};

export type VisitDoctorValue = {
  reserveForm: reserveFormType | undefined;
  setReserveForm: any;
};

export const VisitDoctorContext = createContext<VisitDoctorValue>({
  reserveForm: {
    ReserveFor: "",
    ReservePatient: {
      pid: "",
      firstName: "",
      lastname: "",
      phone: "",
      dateOfBirth: "",
      gender: "",
      citizenship: "",
    },
    ReserveBy: "",
    ReserveBranch: "",
    ReserveService: 1,
    ReserveServiceDetailed: "",
    ReserveDoctor: "",
    ReserveTime: "",
    ReserveServiceSourceId: "",
  },
  setReserveForm: () => {},
});
