import cn from "classnames";

import { IconWithBorder } from "components";

import "./InfoItem.scss";

interface InfoItemProps {
  icon: string | JSX.Element;
  category?: string;
  text?: string | JSX.Element;
  className?: string;
}

export function InfoItem({ className, icon, category, text }: InfoItemProps) {
  return (
    <div className={cn(className, "info-item")}>
      <IconWithBorder className="info-item__icon">{icon}</IconWithBorder>
      <div className="info-item-content">
        <h6 className="info-item__category">{category}</h6>
        <div className="info-item__text">{text}</div>
      </div>
    </div>
  );
}
