import cn from "classnames";

import { IconItem } from "./IconItem";

import "./AboutClinicIcons.scss";

interface ClinicIconsProps {
  className?: string;
  counters?: {
    key: string;
    attributes: {
      title: string;
      icon: string;
      quantity: string;
    };
  }[];
}

export const AboutClinicIcons = ({ className, counters }: ClinicIconsProps) => {
  return (
    <div className={cn(className, "clinic-icons")}>
      {counters?.map((counter) => (
        <IconItem
          key={counter?.key}
          text={counter?.attributes?.title}
          icon={counter?.attributes?.icon}
        >
          {counter?.attributes?.quantity}
        </IconItem>
      ))}
    </div>
  );
};
