import "./ActionButton.scss";
import cn from "classnames";

export const ActionButton = ({
  className,
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button className={cn(className, "action-btn")} {...props}>
      {children}
    </button>
  );
};
