import { PropsWithChildren } from "react";

import { LocaleProvider } from "./LocaleProvider";
import { MapProvider } from "./MapProvider/MapProvider";
import { QueryProvider } from "./QueryProvider";
import { ReduxProvider } from "./ReduxProvider";
import { AuthProvider } from "./AuthProvider";

interface ProvidersProps {}

export const Providers = ({ children }: PropsWithChildren<ProvidersProps>) => {
  return (
    <AuthProvider>
      <QueryProvider>
        <ReduxProvider>
          <LocaleProvider>
            <MapProvider>{children}</MapProvider>
          </LocaleProvider>
        </ReduxProvider>
      </QueryProvider>
    </AuthProvider>
  );
};
