import { ReactNode } from "react";

interface RenderByConditionProps {
  defaultElement: ReactNode | JSX.Element;
  element: ReactNode | JSX.Element;
  showElement: boolean;
}

export const RenderByCondition = ({
  defaultElement,
  element,
  showElement,
}: RenderByConditionProps) => {
  return <>{showElement ? element : defaultElement}</>;
};
