import { memo, useEffect } from "react";
import cn from "classnames";

import { PropsWithChildren } from "react";

import { ModalClose } from "./ModalClose";
import { ModalNav } from "./ModalNav";

import "./Modal.scss";

interface ModalProps {
  className?: string;
  show?: boolean;
  onClose?: () => void;
  next?: boolean | (() => void);
  prev?: boolean | (() => void);
}

export const Modal = memo(function Modal({
  className,
  onClose,
  show,
  prev,
  next,
  children,
}: PropsWithChildren<ModalProps>) {
  useEffect(() => {
    if (show) {
      document.body.classList.add("fixed_body");
    } else {
      document.body.classList.remove("fixed_body");
    }
  }, [show]);

  return (
    <>
      {show ? (
        <>
          <div
            className="overlay"
            onClick={() => {
              document.body.classList.remove("fixed_body");
              onClose?.();
            }}
          />
          <div className={cn(className, "modal")}>
            {onClose && (
              <ModalClose
                onClick={() => {
                  document.body.classList.remove("fixed_body");
                  onClose();
                }}
              />
            )}
            <ModalNav prev={prev} next={next} />
            {children}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
});
