import React, { Suspense, useContext } from "react";

import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { PrivateLayout } from "containers";
import { Loading } from "components";

import {
  AuthContext,
  AuthorizationStage,
} from "providers/AuthProvider/context/AuthContext";

const PRPersonalPage = React.lazy(() => import("views/private/PersonalPage"));
const PRVisitDoctor = React.lazy(() => import("views/private/VisitDoctor"));
const PRUnsatisfaction = React.lazy(
  () => import("views/private/Unsatisfaction/Unsatisfaction")
);
const PRAnalysis = React.lazy(() => import("views/private/Analysis"));
const PRAnalysisResult = React.lazy(
  () => import("views/private/Analysis/AnalysisResult")
);

const PRAnalysisDetailInfo = React.lazy(
  () => import("views/private/Analysis/AnalysisDetailInfo/AnalysisDetailInfo")
);
// const PRAnalysisReportPDF = React.lazy(
//   () => import("views/private/Analysis/PDF")
// );
const PRNotifications = React.lazy(() => import("views/private/Notifications"));
const PRPayments = React.lazy(() => import("views/private/Payments"));
const PRSettings = React.lazy(() => import("views/private/Settings"));

const NotFound = React.lazy(() => import("views/public/404"));

export function PrivateRoutes() {
  const { authorizationStage } = useContext(AuthContext);
  const location = useLocation();

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          element={
            authorizationStage === AuthorizationStage.AUTHORIZED ? (
              <PrivateLayout />
            ) : (
              <Navigate to="/" />
            )
          }
        >
          {/* <Route path="/pr-personal" element={<PRPersonalPage />} /> */}
          <Route path="/pr-unsatisfaction" element={<PRUnsatisfaction />} />
          <Route path="/pr-personal" element={<PRVisitDoctor />} />

          <Route path="/pr-visit-doctor/*" element={<PRVisitDoctor />} />
          <Route path="/pr-analysis" element={<PRAnalysis />} />
          <Route
            path="/pr-analysis/:analysisID"
            element={<PRAnalysisResult />}
          />
          <Route
            path="/pr-analysis/:analysisID/:analysisResultID"
            element={<PRAnalysisDetailInfo />}
          />
          {/* <Route
            path="/pr-analysis/report/:reportID"
            element={<PRAnalysisReportPDF />}
          /> */}
          <Route path="/pr-notifications/*" element={<PRNotifications />} />
          <Route path="/pr-payments" element={<PRPayments />} />
          <Route path="/pr-settings/*" element={<PRSettings />} />
          <Route path="/pr-personal-page/*" element={<PRPersonalPage />} />
          {location?.pathname?.includes("/pr-") && (
            <Route path={"/*"} element={<NotFound />} />
          )}
        </Route>
      </Routes>
    </Suspense>
  );
}
