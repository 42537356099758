import cn from "classnames";
import { Accordion } from "react-accessible-accordion";
import { generateTimeString } from "utils/times/generateTimeString";

import "./AccordionComponent.scss";
import { AccordionComponentItem } from "./AccordionComponentItem";

interface AccordionComponentProps {
  className?: string;
  branches?: boolean;
  data: {
    id?: number;
    title: string;
    description?: string | JSX.Element;
    content?: string | JSX.Element;
    icon?: string;
    key?: string;
    address?: string;
    working_time?: {
      monday: string[];
      tuesday: string[];
      wednesday: string[];
      thursday: string[];
      friday: string[];
      saturday: string[];
      sunday: string[];
      exceptions: string[];
    };
  }[];
  searchValue?: string;
}

export function AccordionComponent({
  className,
  branches,
  data,
  searchValue
}: AccordionComponentProps) {
  return (
    <Accordion allowZeroExpanded className={cn(className, "accordion")}>
      {data?.map((data) => {
        if (data?.title && !branches) {
          return (
            <AccordionComponentItem
              question={data?.title}
              answer={data?.description || data?.content || ""}
              icon={data?.icon}
              key={data?.key || data?.id}
              categoryId={data?.id?.toString()}
              searchValue={searchValue}
            />
          );
        }
        if (data?.title?.includes(searchValue || "") && branches) {
          return (
            <AccordionComponentItem
              question={data?.title}
              answer={
                <div className="accordion__item__branches">
                  <div className="accordion__item__branches__section">
                    <h4 className="accordion__item__branches__section_heading">
                      მისამართი:
                    </h4>
                    <span>{data?.address}</span>
                  </div>
                  <div className="accordion__item__branches__section">
                    <h4 className="accordion__item__branches__section_heading">
                      ტელეფონი:
                    </h4>
                    <span>{data?.address}</span>
                  </div>
                  <div className="accordion__item__branches__section">
                    <h4 className="accordion__item__branches__section_heading">
                      სამუშაო საათები:
                    </h4>
                    <div className="accordion__item__branches__section_time">
                      {data?.working_time &&
                        generateTimeString(data?.working_time)
                          .split(" &nbsp; ")
                          ?.map((time, i) => {
                            return (
                              <div key={i}>{`${time} ${
                                data?.working_time &&
                                i !==
                                  generateTimeString(data?.working_time)?.split(
                                    " &nbsp; "
                                  )?.length -
                                    1
                                  ? ","
                                  : ""
                              }`}</div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              }
              icon={data?.icon}
              key={data?.key || data?.id}
            />
          );
        } else {
          return null;
        }
      })}
    </Accordion>
  );
}
