import React, { useEffect } from 'react';
import "./FacebookComment.scss";

interface FacebookCommentProps {
    link: string;
    title: string;
}
export function FacebookComment({ link, title }: FacebookCommentProps) {
  useEffect(() => {
    if (window.FB) {
        window.FB.XFBML.parse();
    }
}, [link, title]);

return (
    <div className="facebookComment">
        <div className="title">{title}</div>

        <div
            className="fb-comments"
            data-href={link}
            data-width="100%"
            data-numposts="5"
        ></div>
    </div>
);
}


