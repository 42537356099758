import { Button } from "components";
import { Link } from "react-router-dom";
import "./AuthFormWrapper.scss";

interface AuthFormWrapperProps {
  children: React.ReactNode;
  headerText: string;
  question?: string;
  footerText: string;
  link: string;
}

export function AuthFormWrapper({
  children,
  headerText,
  question,
  footerText,
  link,
}: AuthFormWrapperProps) {
  return (
    <div className="auth-form">
      <h3 className="auth-form__header-text">{headerText}</h3>
      {children}
      <div className="auth-form__question">
        <p className="auth-form__question-text">{question}</p>
        <Link to={link}>
          <Button className="auth-form__footer-text">{footerText}</Button>
        </Link>
      </div>
    </div>
  );
}
