import React, { PropsWithChildren, useEffect, useState } from "react";
import { ArrowContainer, Popover, PopoverPosition } from "react-tiny-popover";

import { PRRadioCircle } from "../PRRadioCircle";
import cn from "classnames";

import "./PRRadioPrimary.scss";

interface PRRadioPrimaryProps {
  className?: string;
  active?: boolean;
  onClick?: (e?:any) => void;
  withPopover?: boolean | string;
  position?: PopoverPosition;
}

export const PRRadioPrimary = ({
  className,
  children,
  active,
  onClick,
  withPopover,
  position = "top",
}: PropsWithChildren<PRRadioPrimaryProps>) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [parent, setParent] = useState<HTMLElement>(document.body);

  useEffect(() => {
    setParent(document.getElementById("pr-radio")!);
  }, [document.getElementById("pr-radio")!]);

  return (
    <>
      {withPopover ? (
        <Popover
          parentElement={parent}
          isOpen={isPopoverOpen}
          positions={[position]}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={""}
              arrowSize={14}
              arrowClassName="pr-radio-popover-arrow"
            >
              <div>{withPopover}</div>
            </ArrowContainer>
          )}
          reposition={false}
          onClickOutside={() => setIsPopoverOpen(false)}
        >
          <button
            onMouseEnter={() => setIsPopoverOpen(true)}
            onMouseLeave={() => setIsPopoverOpen(!false)}
            id="pr-radio"
            className={cn(className, "pr-radio-primary", {
              ["pr-radio-primary-active"]: active,
            })}
            onClick={onClick}
          >
            <div className="pr-radio-circle-wrapper">
              <PRRadioCircle active={active} />
            </div>
            {children}
          </button>
        </Popover>
      ) : (
        <button
          id="pr-radio"
          className={cn(className, "pr-radio-primary", {
            ["pr-radio-primary-active"]: active,
          })}
          onClick={onClick}
        >
          <PRRadioCircle active={active} />
          {children}
        </button>
      )}
    </>
  );
};
