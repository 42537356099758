import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { languages_enum, globalSliceState } from "./globalSlice.types";
import { LocalStorage } from "config/keys";

const initialState: globalSliceState = {
  language:
    (localStorage.getItem(LocalStorage.language) as languages_enum) ||
    languages_enum.GEORGIAN,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    changeLanguage: (
      state: globalSliceState,
      action: PayloadAction<languages_enum>
    ) => {
      state.language = action.payload;
      localStorage.setItem(LocalStorage.language, action.payload);
    },
  },
});

export const { changeLanguage } = globalSlice.actions;

export default globalSlice.reducer;
