import { useEffect, useState } from "react";

import cn from "classnames";

import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

import "./Accordion.scss";

interface AccordionProps {
  title: string | JSX.Element;
  className?: string;
  open?: boolean;
  onChange?: (value: boolean) => void;
  children?: React.ReactNode;
  active?: boolean;
  id?: string;
  onClick?: () => void;
}

export const Accordion = ({
  title,
  children,
  onChange,
  open,
  className,
  active,
  id,
  onClick
}: AccordionProps) => {
  const [isActive, setIsActive] = useState(false);

  const controlWith = () => (onChange ? open : isActive);

  const toggleAccordion = () => {
    if (onChange) return onChange(!open);
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (active) {
      setIsActive(true);
    }
  }, [active]);

  return (
    <div
      id={id}
      className={cn(className, "accordion", `${isActive && "active"}`)}
      onClick={onClick}
    >
      <div className="accordion-item" onClick={toggleAccordion}>
        <div className="accordion-item__title">{title}</div>
        <button className="accordion-item__button" role="button" aria-label="accordion" onClick={toggleAccordion}>
          {controlWith() ? <AiOutlineUp /> : <AiOutlineDown />}
        </button>
      </div>
      {controlWith() && <div className="accordion__content">{children}</div>}
    </div>
  );
};
