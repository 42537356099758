import cn from "classnames";

import "./TextArea.scss";

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  wrapperClassName?: string;
  bottom?: JSX.Element | React.ReactNode;
  top?: JSX.Element | React.ReactNode;
}

export const TextArea = ({
  wrapperClassName,
  bottom,
  top,
  ...props
}: TextAreaProps) => {
  return (
    <div className={cn(wrapperClassName, "textarea-wrapper")}>
      {top && <div className="textarea-top">{top}</div>}
      <textarea className="textarea" {...props} />
      {bottom && <div className="textarea-bottom">{bottom}</div>}
    </div>
  );
};
