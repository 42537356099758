import { createContext } from "react";

export enum AuthorizationStage {
  AUTHORIZED = "authorized",
  AUTHORIZING = "authorizing",
  UNAUTHORIZED = "unauthorized"
}

export type userDataType = {
  id: number;
  name: string;
  last_name: string;
  email: string;
  email_verified_at?: string;
  phone: string;
  phone_verified_at?: string;
  gender: string;
  birthdate?: string;
  sid: string;
  citizenship: string;
  photo?: string;
  show_in_comments: string;
  last_login_at?: string;
  created_at: string;
  updated_at: string;
}

type AuthContextValue = {
  authorizationStage: AuthorizationStage;
  setAuthorizationStage: (authorizationStage: AuthorizationStage) => void;
  token: string | null;
  setToken: (token: string | null) => void;
  userData: userDataType | null;
  setUserData: (
    userData: userDataType | null
  ) => void;
};

export const AuthContext = createContext<AuthContextValue>({
  authorizationStage: AuthorizationStage.UNAUTHORIZED,
  setAuthorizationStage: () => {},
  token: null,
  setToken: () => {},
  userData: null,
  setUserData: () => {}
});
