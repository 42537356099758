import './NavigationMobileItem.scss';
import { ReactNode, useState } from 'react';
import { RiArrowDownSFill } from 'react-icons/ri';
import cn from "classnames";
export interface INavigationMobileItem {
    title?: string;
    icon?: string;
    children?: ReactNode;
}

export const NavigationMobileItem = ({title,children,icon}:any) => {
    const [ open, setOpen ] = useState<boolean>(false);
    
    return (
        <div className="inner-accord">
            <div className="inner-accord__head" onClick={()=> setOpen(!open)}>
                <div className="inner-accord__head-container">
                    <div className="inner-accord__icon">{icon}</div>
                    {title}
                </div>
                <RiArrowDownSFill className={cn(
                    {"inner-accord__icon--rotated": open},
                    "inner-accord__icon",
                    "inner-accord__icon--nospace",
                )}/>
            </div>
            {
                open && <div className="inner-accord__body">
                    {children}
                </div>
            }
        </div>
    )
}