import cn from "classnames";
import { PropsWithChildren } from "react";
import { AiOutlineGift } from "react-icons/ai";

import "./PromotionLabel.scss";

export enum PromotionModeEnum {
  Present = "present",
  Percent = "percent",
}

export interface PromotionLabelProps {
  className?: string;
  promotionMode: PromotionModeEnum;
}

export const PromotionLabel = ({
  className,
  children,
  promotionMode,
}: PropsWithChildren<PromotionLabelProps>) => {
  return (
    <div
      className={cn(className, {
        ["promotion-present"]: promotionMode === PromotionModeEnum.Present,
        ["promotion-percent"]: promotionMode === PromotionModeEnum.Percent,
      })}
    >
      {promotionMode === PromotionModeEnum.Present ? (
        <AiOutlineGift />
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
