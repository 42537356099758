import cn from "classnames";

import "./Tabs.scss";

import { TabsButton } from "components/Tabs/TabsButton";
interface TabsProps {
  children: JSX.Element | React.ReactNode;
  className?: string;
}

export function Tabs({ children, className }: TabsProps) {
  return <div className={cn(className, "tabs__wrapper")}>{children}</div>;
}

Tabs.TabsButton = TabsButton;
