/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "providers/AuthProvider/context/AuthContext";
import avatar from "../../assets/images/profile-template.png";

import "./NavProfile.scss";
import { ArrowContainer, Popover, PopoverPosition } from "react-tiny-popover";
import { MdKeyboardArrowDown, MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { PRSidebarLink } from "components/PRSidebar/PRSidebarDesktop/PRSidebarLink";
import { FormattedMessage } from "react-intl";
import { AiFillHome, AiFillSetting } from "react-icons/ai";
import { PRSidebarButton } from "components/PRSidebar/PRSidebarDesktop/PRSidebarButton";
import { LocalStorage } from "config/keys";
import { generateImageLink } from "utils";

interface NavProfileProps {
  className?: string;
  position?: PopoverPosition;
  mobile?: boolean;
}

const NavProfile = ({ position = "bottom" }: NavProfileProps) => {
  const navigate = useNavigate();
  const { userData, setUserData, setToken } = useContext(AuthContext);
  const [profileInfo, setProfileInfo] = useState(userData);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [parent, setParent] = useState(document.body);

  useEffect(() => {
    localStorage.getItem("userData") &&
      // @ts-ignore
      setProfileInfo(JSON.parse(localStorage.getItem("userData")));
  }, [localStorage.getItem("userData")]);

  useEffect(() => {
    profileInfo && setParent(document.getElementById("navProfile")!);
  }, [document.getElementById("navProfile")!]);

  if (profileInfo) {
    return (
      <Popover
        parentElement={parent}
        isOpen={isPopoverOpen}
        positions={[position]}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={""}
            arrowSize={15}
            arrowClassName="navProfile-popover-arrow"
          >
            <nav>
              <PRSidebarLink to="pr-visit-doctor/current" icon={<AiFillHome />}>
                <FormattedMessage
                  id="private.sidebar.personal-page"
                  defaultMessage="_personal-page_"
                />
              </PRSidebarLink>
              <PRSidebarLink to="pr-settings" icon={<AiFillSetting />}>
                <FormattedMessage
                  id="private.sidebar.settings"
                  defaultMessage="_settings_"
                />
              </PRSidebarLink>
              <PRSidebarButton
                icon={<MdLogout />}
                onClick={() => {
                  localStorage.removeItem(LocalStorage.AUTH_TOKEN);
                  sessionStorage.removeItem(LocalStorage.AUTH_TOKEN);
                  localStorage.removeItem("userData");
                  sessionStorage.removeItem("userData");
                  setUserData(null);
                  setToken(null);
                  navigate("/");
                }}
              >
                <FormattedMessage
                  id="private.sidebar.logout"
                  defaultMessage="_logout_"
                />
              </PRSidebarButton>
            </nav>
          </ArrowContainer>
        )}
        reposition={false}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <section
          id="navProfile"
          className="navProfile"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <div className="navProfile_top">
            <img
              src={
                profileInfo?.photo
                  ? generateImageLink({ imageId: profileInfo?.photo })
                  : avatar
              }
              alt="User"
              loading="lazy"
            />
            <div className="navProfile_topRight">
              <h5 className="userName">
                {profileInfo.name} <br />
                {profileInfo.last_name}
              </h5>
              <MdKeyboardArrowDown className="language-changer__expand" />
            </div>
          </div>
        </section>
      </Popover>
    );
  } else {
    return null;
  }
};
export { NavProfile };
