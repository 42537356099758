import cn from "classnames";
import "./CheckBox.scss";
import { InputHTMLAttributes } from "react";

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  text?: string | JSX.Element;
  rules?: JSX.Element;
  className?: string;
  active?: boolean;
}

export function CheckBox({ text, rules, className, active, ...props }: CheckBoxProps) {
  return (
    <div className={cn(className, "checkbox__remember")}>
      <label className="checkbox-wrapper">
        {text}
        <input type="checkbox" checked={active} {...props}/>
        <span className="checkbox-new"></span>
      </label>
      {rules}
    </div>
  );
}
