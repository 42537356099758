import { useWindowSize } from "rooks";
import "./PRSidebar.scss";
import { PRSidebarDesktop } from "./PRSidebarDesktop";
import { PRSidebarMobile } from "./PRSidebarMobile";

export const PRSidebar = () => {
  const { innerWidth } = useWindowSize();

  return (
    <>
      {innerWidth && innerWidth <= 1200 ? (
        <PRSidebarMobile />
      ) : (
        <PRSidebarDesktop />
      )}
    </>
  );
};
