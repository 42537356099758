import { AiFillHome } from "react-icons/ai";
import { IoMdNotifications } from "react-icons/io";
import { RiCalendarCheckFill, RiFlaskFill } from "react-icons/ri";
import { FormattedMessage, useIntl } from "react-intl";
import { PRSidebarLink } from "../PRSidebarDesktop/PRSidebarLink";
import "./PRSidebarMobile.scss";
import { Link, useLocation } from "react-router-dom";
import { PRButton } from "components/PRButton";

export function PRSidebarMobile() {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  return pathname.endsWith("/pr-personal") ? (
    <div className="sidebar-mobile">
      {/* <PRSidebarLink to="pr-personal" icon={<AiFillHome />}>
        <FormattedMessage
          id="private.sidebar.personal-page"
          defaultMessage="_personal-page_"
        />
      </PRSidebarLink> */}
      {/* <PRSidebarLink
        to="pr-visit-doctor/current"
        icon={<RiCalendarCheckFill />}
      >
        <FormattedMessage
          id="private.sidebar.visit-doctor"
          defaultMessage="_visit-doctor_"
        />
      </PRSidebarLink> */}
      <Link
        to="/pr-visit-doctor/reserve"
        className="sidebar-mobile__button-fluid"
      >
        <PRButton mode="primary" className="sidebar-mobile__button-fluid">
          {formatMessage({
            id: "private.visit-doctor.button.reserve",
            defaultMessage: "_Reserve_",
          })}
        </PRButton>
      </Link>

      <PRSidebarLink
        to="pr-notifications"
        icon={
          <div className="sidebar-desktop__notification">
            <IoMdNotifications />
          </div>
        }
      >
        <FormattedMessage
          id="private.sidebar.notifications"
          defaultMessage="_notifications_"
        />
      </PRSidebarLink>
      {/* <PRSidebarLink to="pr-analysis" icon={<RiFlaskFill />}>
        <FormattedMessage
          id="private.sidebar.analysis"
          defaultMessage="_analysis_"
        />
      </PRSidebarLink>
       */}
    </div>
  ) : null;
}
