import cn from "classnames";
import { Link } from "react-router-dom";

import aversiLogo from "assets/images/aversi-logo.svg";

import { LanguageChanger } from "components/LanguageChanger";
import "./AuthHeader.scss";

interface AuthHeaderProps {
  className: string;
}

export function AuthHeader({ className }: AuthHeaderProps) {
  return (
    <div className={cn(className, "auth-header")}>
      <Link className="auth-header__logo" to="/">
        <img src={aversiLogo} alt="aversi-logo" loading="lazy" />
      </Link>
      {/* <LanguageChanger /> */}
    </div>
  );
}
