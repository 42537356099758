import cn from "classnames";

import "./ModalButton.scss";

interface ModalButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const ModalButton = ({
  className,
  children,
  onClick,
}: React.PropsWithChildren<ModalButtonProps>) => {
  return (
    <button className={cn(className, "modal-button")} onClick={onClick}>
      {children}
    </button>
  );
};
