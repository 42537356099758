import { Button, TextInput } from "components";
import { useNavigate } from "react-router-dom";
import { AuthFormWrapper } from "../../AuthFormWrapper";
import "./ResetPasswordInitial.scss";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { privateRequest } from "utils/privateRequest";

interface ResetPasswordInitialProps {
  saveData: any;
}
export function ResetPasswordInitial({ saveData }: ResetPasswordInitialProps) {
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("mail");
  const [code, setCode] = useState("");
  const [mail, setMail] = useState("");
  const [mailError, setMailError] = useState("");
  const [codeSent, setCodeSent] = useState(false);

  var myHeaders = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json"
  };

  const handleCheckMail = useMutation(
    () =>
      privateRequest({
        url: `/api/reset/check?email=${mail}`,
        method: "post",
        headers: myHeaders
      }),
    {
      onSuccess: (response: any) => {
        if (!response?.data?.status) {
          setMailError(response?.data?.message);
        } else {
          setMailError("");
          handleSendCode.mutate();
        }
      },
      onError: (response: any) => {
        console.error(response);
        response?.response?.data?.message === "Email is not registered"
          ? setMailError("მითითებული ელ.ფოსტა რეგისტრირებული არ არის")
          : setMailError(response?.response?.data?.errors?.email?.[0]);
      }
    }
  );

  const handleSendCode = useMutation(
    () =>
      privateRequest({
        url: `/api/reset/send?email=${mail}`,
        method: "post",
        headers: myHeaders
      }),
    {
      onSuccess: (response: any) => {
        setInputType("code");
        if (!response?.data?.status) {
          setMailError(response?.data?.message);
        } else {
          setInputType("code");
          setMailError("");
          setCodeSent(true);
        }
      },
      onError: (response: any) => {
        console.error(response);
      }
    }
  );

  const handleVerifyCode = useMutation(
    () =>
      privateRequest({
        url: `/api/reset/verify?email=${mail}&reset_code=${code}`,
        method: "post",
        headers: myHeaders
      }),
    {
      onSuccess: (response: any) => {
        setInputType("code");
        if (!response?.data?.status) {
          setMailError(response?.data?.message);
        } else {
          setMailError("");
          saveData({ email: mail, reset_code: code });
          navigate("/complete-reset-password-initial");
        }
      },
      onError: (response: any) => {
        setMailError(
          response?.response?.data?.message === "Invalid reset code"
            ? "კოდი არასწორია"
            : response?.response?.data?.message
        );
      }
    }
  );
  const [seconds, setSeconds] = useState(0);
  const countdown = (number: number) => {
    let result = number;
    if (result > 0) {
      setTimeout(() => {
        setSeconds(result - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    if (handleSendCode?.isSuccess) {
      setSeconds(59);
    }
  }, [handleSendCode?.isSuccess]);
  const memorizedCountdown = useCallback(
    () => seconds && countdown(seconds),
    [seconds]
  );
  memorizedCountdown();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    inputType === "mail" && handleCheckMail.mutate();
    inputType === "code" && code && handleVerifyCode.mutate();
  };
  return (
    <AuthFormWrapper
      headerText="პაროლის აღდგენა"
      footerText="რეგისტრაცია"
      question="არ გაქვთ ანგარიში?"
      link="/registration"
    >
      <form onSubmit={handleSubmit} id="resetPasswordEmail">
        <div className="reset-password-initial-wrapper">
          {inputType === "mail" ? (
            <TextInput
              wrapperClassName="reset-password-initial-wrapper-input"
              placeholder={"ელ.ფოსტა"}
              startValue={mail}
              handleValue={(value) => setMail(value)}
              errorMessage={mailError}
              className={'reset-input'}
            />
          ) : (
            <TextInput
              wrapperClassName="reset-password-initial-wrapper-input"
              placeholder={"კოდი"}
              startValue={""}
              handleValue={(value) => setCode(value)}
              errorMessage={mailError}
            />
          )}
          {codeSent && (
            <p className="reset-password-initial-wrapper-code-status">
              კოდი გამოგზავნილია ელ.ფოსტაზე <span>{mail}</span>
            </p>
          )}
          <span
            onClick={handleSubmit}
            className={`reset-password-initial-wrapper-get-code ${
              handleSendCode?.isSuccess
                ? "reset-password-initial-wrapper-get-code-sent"
                : ""
            }`}
          >
            {handleSendCode?.isSuccess ? seconds : "მიიღეთ კოდი"}
          </span>
        </div>
      </form>

      <Button
        form="resetPasswordEmail"
        disabled={handleSendCode?.isLoading}
        type="submit"
        className={`reset-password-initial-button ${
          handleSendCode?.isLoading
            ? "reset-password-initial-button-inActive"
            : ""
        }`}
      >
        შემდეგ
      </Button>
    </AuthFormWrapper>
  );
}
