import cn from "classnames";

import { FooterRows } from "./FooterRows";
import { FooterBottom } from "./FooterBottom";

import "./Footer.scss";

interface FooterProps {
  className?: string;
}

export const Footer = ({ className }: FooterProps) => {
  return (
    <div className={cn(className, "footer-wrapper")}>
      <div className="footer-rows">
        <FooterRows />
      </div>
      <FooterBottom />
    </div>
  );
};
