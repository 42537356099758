import "./AboutClinicInfo.scss";

interface ClinicInfoProps {
  className?: string;
  content?: string | JSX.Element;
}

export const AboutClinicInfo = ({ className, content }: ClinicInfoProps) => {
  return <div className="clinic-info">{content}</div>;
};
