import { PRFilterButton } from "./PRFilterButton";
import { PRTextButton } from "./PRTextButton";

import cn from "classnames";

import "./PRButton.scss";

interface PRButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: "primary" | "secondary" | "third";
}

export const PRButton = ({
  className,
  children,
  mode,
  ...props
}: PRButtonProps) => {
  return (
    <button
      className={cn(className, "pr-button", {
        ["pr-button__primary"]: mode === "primary",
        ["pr-button__secondary"]: mode === "secondary",
        ["pr-button__third"]: mode === "third",
      })}
      {...props}
    >
      {children}
    </button>
  );
};

PRButton.PRFilterButton = PRFilterButton;
PRButton.PRTextButton = PRTextButton;

PRButton.defaultProps = {
  mode: "primary",
};
