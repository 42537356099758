import cn from "classnames";
import { PropsWithChildren } from "react";

import "./NavigationMobileLink.scss";

interface NavigationMobileLinkProps {
  icon?: React.ReactNode | JSX.Element;
  className?: string;
  onClick?: () => void;
}

export const NavigationMobileLink = ({
  icon,
  children,
  className,
  onClick,
}: PropsWithChildren<NavigationMobileLinkProps>) => {
  return (
    <div onClick={onClick} className={cn(className, "navigation-mobile-link")}>
      <span className="navigation-mobile-link__icon">{icon}</span>
      {children}
    </div>
  );
};
