import { PropsWithChildren, useCallback } from "react";

import englishLocale from "./transltations/en.json";
import georgianLocale from "./transltations/ge.json";
import russianLocale from "./transltations/ru.json";

import { IntlProvider, ReactIntlErrorCode } from "react-intl";

import { useSelector } from "react-redux";

import { RootState } from "redux/store";

import { languages_enum } from "redux/actions/globalSlice";

interface LocaleProviderProps {}

function onError(e: any) {
  if ((e.code = ReactIntlErrorCode.MISSING_DATA)) {
    return;
  }
  console.error(e);
}

export const LocaleProvider = ({
  children,
}: PropsWithChildren<LocaleProviderProps>) => {
  const { language } = useSelector((state: RootState) => state.global);

  const getCurrentLocale = useCallback(
    (language: languages_enum) => {
      if (language === languages_enum.ENGLISH) {
        return englishLocale;
      } else if (language === languages_enum.RUSSIAN) {
        return russianLocale;
      } else {
        return georgianLocale;
      }
    },
    [language]
  );

  return (
    <IntlProvider
      onError={onError}
      defaultLocale="ge"
      messages={getCurrentLocale(language)}
      locale={language}
    >
      {children}
    </IntlProvider>
  );
};
