import { useCallback, useState, useEffect } from "react";
import cn from "classnames";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { generateImageLink } from "utils";
import { SearchResultsItem } from "../SearchResultsItem";
import defaultPhoto from "assets/images/default-photo.png";

import "./SearchResultsFound.scss";
import { getHighlightedText } from "./utils/getHighlightedText";
import { Pagination } from "components";

interface SearchResultsFoundProps {
  quantity?: string | number;
  resultItems?: {
    id: string;
    name: string;
    type: string;
    image: string;
    cover_image: string;
    photo: string;
    title?: string;
    slug?: string;
  }[];
  maxPages?: number;
  pageSize?: number;
  className?: string;
  keyword?: string;
  onPageChange?: (page: number) => void;
  clearSearch?: () => void;
}

export function SearchResultsFound({
  keyword,
  quantity,
  resultItems,
  className,
  pageSize,
  maxPages = 1,
  onPageChange,
  clearSearch,
}: SearchResultsFoundProps) {
  const navigate = useNavigate();
  const { page } = useParams();

  useEffect(() => {
    if (Number(page) <= maxPages) {
      setCurrentPage(Number(page));
    }
  }, [page, maxPages]);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const createRoute = useCallback((item: any) => {
    switch (item.type) {
      case "Doctors":
        return `/doctors/1/doctor/${item?.id}/${item?.name}`;
      case "Blog":
        return `/blog/1/${item?.id}/${item?.slug}`;
      case "News":
        return `/news/1/${item?.id}/${item?.slug}`;
      case "Promotions":
        return `/promotions/1/${item?.id}/${item?.title}`;
      case "Vacancy":
        return `/vacancies/${item?.id}/${item?.slug}`;
      case "Gallery":
        return `/gallery/${item?.id}/${item?.title}`;
      case "LaboratoryService":
        return `/laboratory-analysis/${item?.id}`;
      case "Service":
        return `/services/${item?.id}`;
      default:
        return `/`;
    }
  }, []);

  const { formatMessage } = useIntl();
  return (
    <div className={cn(className)}>
      <div className="search-results__quantity">მოიძებნა {quantity} შედეგი</div>
      <div className="search-results__wrapper">
        {resultItems?.map((item) => {
          return (
            <SearchResultsItem
              onClick={() => {
                clearSearch?.();
                navigate(createRoute(item));
              }}
              image={
                generateImageLink({
                  imageId: item?.cover_image || item?.photo,
                }) || defaultPhoto
              }
              category={formatMessage(
                {
                  id: "search.category",
                },
                { category: item?.type }
              )}
              content={getHighlightedText(item?.title || item?.name, keyword)}
              key={item?.id}
            />
          );
        })}
        <Pagination
          className="search-results-pagination"
          maxPages={maxPages}
          pageSize={pageSize || 10}
          currentPage={currentPage}
          onChange={(currentPage: number) => {
            onPageChange?.(currentPage);
            navigate("/search-results/" + currentPage);
          }}
        />
      </div>
    </div>
  );
}
