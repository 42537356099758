import cn from "classnames";
import { TextInput } from "components/TextInput";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";

import "./SearchInput.scss";

import notFoundSmall from "assets/images/not-found-sm.png";
import { Button } from "components/Button";
import { SearchResultsFound } from "views/public/SearchResults/SearchResultsFound";
import { SearchResultsNotFound } from "views/public/SearchResults/SearchResultsNotFound";
import { Link } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { useDebounce, useOutsideClick } from "rooks";
import { useSearchInput } from "./hooks";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export interface SearchInputProps {}

export const SearchInput = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const searchKeyword = useSelector((state: RootState) => state.search.keyword);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [parent, setParent] = useState(document.body);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [open, setOpen] = useState<"open" | "close" | "default">("default");

  useEffect(() => {
    setParent(document.getElementById("search-input-custom")!);
  }, [document.getElementById("search-input-custom")!]);
  useOutsideClick(inputRef, () => {
    if (open === "open") {
      setOpen("close");
      setIsPopoverOpen(false);
    }
  });

  const { searchResults } = useSearchInput({
    searchTerm: searchTerm
  });

  const closeSearchHandler = (el: HTMLInputElement) => {
    el.value = "";
    setOpen("close");
    setIsPopoverOpen(false);
    setSearchTerm("");
  };

  const clearInputHandler = (el: HTMLInputElement) => {
    el.value = "";
  };

  return (
    <Popover
      ref={inputRef}
      parentElement={parent}
      isOpen={!!searchTerm && isPopoverOpen}
      positions={["bottom", "top", "left", "right"]}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <>
          {searchResults && searchResults?.length !== 0 && (
            <>
              <SearchResultsFound
                clearSearch={() => {
                  setOpen("close");
                  setIsPopoverOpen(false);
                  setSearchTerm("");
                }}
                className="search-results-input"
                quantity={searchResults?.length}
                resultItems={searchResults}
                keyword={searchKeyword}
              />
              <Link
                to="/search-results/1"
                onClick={() => {
                  setOpen("close");
                  setIsPopoverOpen(false);
                }}
              >
                <Button className="search-results-all">ყველა</Button>
              </Link>
            </>
          )}
          {!searchResults ||
            (searchResults?.length === 0 && (
              <SearchResultsNotFound
                className="search-results-input-not-found"
                text="უკაცრავად, არაფერი არ მოიძებნა"
                image={notFoundSmall}
              />
            ))}
        </>
      }
    >
      <div
        className={cn("search-input-wrapper", {
          ["wrapper-open"]: open === "open"
        })}
        id="search-input-custom"
      >
        <TextInput
          onBlur={(el) => clearInputHandler(el.target)}
          onClickClose={closeSearchHandler}
          onChange={useDebounce((e) => setSearchTerm(e.target.value), 500)}
          ref={inputRef}
          onFocus={() => setIsPopoverOpen(true)}
          placeholder="მოძებნე"
          wrapperClassName={cn("search-input", {
            ["search-input-default"]: open === "default",
            ["search-input-visible"]: open === "open",
            ["search-input-close"]: open === "close"
          })}
        />
        <AiOutlineSearch
          className="search-input-closed"
          onClick={() => setOpen("open")}
        />
      </div>
    </Popover>
  );
};
