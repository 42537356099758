import { useQuery } from "react-query";
import { publicRequest } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const useLaboratoryAnalysis = (id?: string, searchValue?: string) => {
  const { language } = useSelector((state: RootState) => state.global);

  const { data: laboratoryAnalsysis } = useQuery(
    [language, id, "laboratoryAnalsysis", searchValue],
    () =>
      publicRequest({
        url: `/api/analysis/${language}?page=1&per_page=15&category_id=${id}&search_term=${
          searchValue || ""
        }`,
        method: "get"
      })
  );

  return {
    laboratoryAnalsysis: laboratoryAnalsysis?.data?.data
  };
};
