import { Outlet } from "react-router-dom";
import { Navigation, PRSidebar } from "components";

import { useWindowSize } from "rooks";

import "./PrivateLayout.scss";

export const PrivateLayout = () => {
  const { innerHeight } = useWindowSize();

  return (
    <div className="private-layout" style={{ minHeight: `${innerHeight}px` }}>
      <Navigation />
      <div className="private-layout-container">
        <PRSidebar />
        <div className="private-layout-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
