import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER_URL}/`,
});

export function publicRequest({ ...options }) {
  if (options?.method === "POST") {
    return client.post(options.url, options.body);
  } else {
    return client(options);
  }
}

client.interceptors.response.use(
  (response: any) => new Promise((resolve: any) => resolve(response)),
  (error) => {
    if (error.response.status === 403) {
      if (error.response.config.method !== "get") {
        console.error(error.response.data?.message);
      }
    }
    return Promise.reject(error);
  }
);
