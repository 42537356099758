import { ModalButton } from "../ModalButton";

import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight
} from "react-icons/md";

import "./ModalNav.scss";

interface ModalNavProps {
  prev?: boolean | (() => void);
  next?: boolean | (() => void);
}

export const ModalNav = ({ prev, next }: ModalNavProps) => {
  return (
    <>
      {prev && (
        <ModalButton
          className="modal__prev nav-button"
          onClick={() => typeof prev !== "boolean" && prev()}
        >
          <MdOutlineKeyboardArrowLeft />
        </ModalButton>
      )}
      {next && (
        <ModalButton
          className="modal__next nav-button"
          onClick={() => typeof next !== "boolean" && next()}
        >
          <MdOutlineKeyboardArrowRight />
        </ModalButton>
      )}
    </>
  );
};
