import cn from "classnames";

import { PropsWithChildren } from "react";

import { PRRadioCircle } from "../PRRadioCircle";

import "./PRRadioSecondary.scss";

interface PRRadioSecondaryProps {
  active?: boolean;
  className?: string;
  onClick?: () => void;
}

export const PRRadioSecondary = ({
  active,
  className,
  children,
  onClick,
}: PropsWithChildren<PRRadioSecondaryProps>) => {
  return (
    <div className={cn("pr-radio-secondary", className)} onClick={onClick}>
      <PRRadioCircle active={active} className="pr-radio-secondary__circle" />
      <div className="pr-radio-secondary__text">{children}</div>
    </div>
  );
};
