import { PropsWithChildren } from "react";

import { QueryClient, QueryClientProvider } from "react-query";

interface QueryProviderProps {}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 60000,
    },
  },
});

export const QueryProvider = ({
  children,
}: PropsWithChildren<QueryProviderProps>) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
