import React, { useRef } from "react";
import cn from "classnames";

import { RiArrowLeftSLine } from "react-icons/ri";
import { PRIconWrapper } from "components";

import { useLocation, useNavigate } from "react-router-dom";

import "./PRCard.scss";

interface PRCardProps {
  className?: string;
  title?: string;
  icon?: React.ReactNode | JSX.Element;
  button?: React.ReactNode | JSX.Element;
  border?: boolean;
  back?: boolean;
  height?: number;
  style?: React.CSSProperties;
  link?: string;
}

export const PRCard = ({
  className,
  title,
  icon,
  children,
  button,
  border = true,
  back,
  height,
  style = {},
  link,
}: React.PropsWithChildren<PRCardProps>) => {
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const location = useLocation();

  return (
    <div
      className={cn(className, "pr-card", { ["pr-card-border"]: border })}
      style={{
        height: `${height && `calc( 100% - ${height}px)`}`,
        ...style,
      }}
    >
      <div className="pr-card__wrapper">
        <div ref={headerRef} className="pr-card__header">
          <div className="pr-card__header--left">
            {back && (
              <RiArrowLeftSLine
                //@ts-ignore
                onClick={() => navigate(link ?? -1)}
                className="pr-card__header--back"
              />
            )}
            {icon && (
              <PRIconWrapper className="pr-card__header--icon">
                {icon}
              </PRIconWrapper>
            )}
            <div className="pr-card__header--title">{title}</div>
          </div>
          {button}
        </div>
        <div
          style={{
            height:
              location.pathname.includes("/reserve-check") ||
              location.pathname.includes("/current/") ||
              location.pathname.includes("/history/") ||
              location.pathname.includes("/pr-analysis") ||
              location.pathname.includes("/pr-payments")
                ? ""
                : "100%",
          }}
          className="pr-card__body"
          // style={{
          //   //@ts-ignore
          //   height: `calc( 100% - ${headerRef.current?.offsetHeight}px)`
          // }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
