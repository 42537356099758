import { PropsWithChildren } from "react";
import cn from "classnames";
import "./NavigationMobileContent.scss";

interface NavigationMobileContentProps {
  active?: boolean;
}

export const NavigationMobileContent = ({
  active,
  children,
}: PropsWithChildren<NavigationMobileContentProps>) => {
  return (
    <div
      className={cn("navigation-mobile-content", {
        ["navigation-mobile-content-active"]: active,
      })}
    >
      {children}
    </div>
  );
};
