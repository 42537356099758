import cn from "classnames";

import "./SearchResultsNotFound.scss";

interface SearchResultsNotFoundProps {
  text?: string | JSX.Element;
  image?: string;
  className?: string;
}

export function SearchResultsNotFound({
  text,
  image,
  className
}: SearchResultsNotFoundProps) {
  return (
    <div className={cn(className, "search-results-not-found")}>
      <div className="search-results-not-found__image">
        <img src={image} alt="not-found" loading="lazy" />
      </div>
      <h4 className="search-results-not-found__text">{text}</h4>
    </div>
  );
}
