import axios from "axios";

export const publicAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER_URL}/`,
});

export function privateRequest({ ...options }) {
  return publicAxios(options);
}

export const privateAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER_URL}/`,
  headers: {
    Accept: "application/json",
  },
});

privateAxios.interceptors.request.use(function (config) {
  if (config && config.headers && !config.headers["Authorization"]) {
    const token = localStorage.getItem("AUTH_TOKEN");

    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

privateAxios.interceptors.response.use(
  (response: any) => new Promise((resolve: any) => resolve(response)),
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);