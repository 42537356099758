import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Providers } from "providers/Providers";

import App from "./App";

import "./assets/styles/cssImports.scss";
import "./assets/styles/global.scss";

// import TagManager from "react-gtm-module";

// const tagManagerArgs = {
//   gtmId: "G-MLHQZ3LYEL"
// };

// TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Providers>
      <App />
    </Providers>
  </BrowserRouter>
);
