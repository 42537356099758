import React, { Suspense, useContext, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Loading } from "components";
import { AuthLayout } from "containers";

import {
  AuthContext,
  AuthorizationStage,
} from "providers/AuthProvider/context/AuthContext";
import { CompleteResetPassword } from "./ResetPassword/CompleteResetPassword/CompleteResetPassword";
import { ResetPasswordInitial } from "./ResetPassword/ResetPasswordInitial";

const Login = React.lazy(() => import("views/auth/Login"));
const RegistrationInitial = React.lazy(() => import("views/auth/Registration"));
const RegistrationNext = React.lazy(
  () => import("views/auth/Registration/RegistrationNext")
);
const CompleteRegistration = React.lazy(
  () => import("views/auth/Registration/CompleteRegistration")
);

export function AuthRoutes() {
  const { authorizationStage } = useContext(AuthContext);
  const [resetPassData, setResetPassData] = useState({
    email: "",
    reset_code: "",
  });

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          element={
            authorizationStage === AuthorizationStage?.AUTHORIZED ? (
              <Navigate to="/pr-visit-doctor/current" />
            ) : (
              <AuthLayout />
            )
          }
        >
          <Route path="/login" element={<Login />} />
          <Route path={"/registration"} element={<RegistrationInitial />} />
          <Route
            path={"/registration-initial-next"}
            element={<RegistrationNext />}
          />
          <Route
            path={"/registration-initial-complete"}
            element={<CompleteRegistration />}
          />
          <Route
            path="/reset-password"
            element={<ResetPasswordInitial saveData={setResetPassData} />}
          />
          <Route
            path="/complete-reset-password-initial"
            element={<CompleteResetPassword data={resetPassData} />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
