import cn from "classnames";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  Grid,
  FreeMode,
  Keyboard,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/grid";

import "./Slider.scss";

interface SliderProps {
  onIndexChange?: (index: number) => void;
  isThumb?: boolean;
  sliderData: Array<any>;
  className?: string;
  prevRef?: React.RefObject<HTMLButtonElement>;
  nextRef?: React.RefObject<HTMLButtonElement>;
  customButtons?: boolean;
  slidesPerView?: number;
  grid?: {
    rows: number;
  };
  spaceBetween?: number;
}

export const Slider = ({
  isThumb,
  onIndexChange,
  sliderData,
  className,
  customButtons,
  prevRef,
  nextRef,
  slidesPerView,
  grid,
  spaceBetween = 10,
}: SliderProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);

  let navigation;

  if (customButtons) {
    navigation = {
      prevEl: prevRef!.current,
      nextEl: nextRef!.current,
    };
  } else if (sliderData?.length > 1) {
    navigation = true;
  }
  const isSliderDataEmpty = !sliderData || sliderData.length === 0;
  return (
    <>
    {isSliderDataEmpty ? null : (
    <div className={cn(className, "slider-container")}>
      <Swiper
        onSlideChange={(swiper) => {
          if (swiper) {
            onIndexChange && onIndexChange(swiper.activeIndex);
          }
        }}
        slidesPerView={slidesPerView}
        grid={grid}
        rewind={true}
        spaceBetween={spaceBetween}
        navigation={navigation}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Thumbs, Keyboard, Pagination, Grid, Navigation]}
        className="swiper-1"
        keyboard={{
          enabled: true,
        }}
      >
        {sliderData && sliderData[0] ? sliderData[0]?.map((data: string | JSX.Element) => (
          <SwiperSlide key={Math.random()}>{data}</SwiperSlide>
        )) : null}
      </Swiper>
      {isThumb && (
        <Swiper
          onSwiper={setThumbsSwiper}
          rewind={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper-2"
        >
          {sliderData.map((data) => (
            <SwiperSlide key={Math.random()}>{data}</SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
    )}
    </>
  );
};
