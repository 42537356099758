import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMain } from "views/public/Main/hooks";

interface IMeta {
  title?: string;
  description?: string;
  canonicalUrl?: string;
  image?: string;
}

const preTitle = "ავერსის კლინიკა";
export const useMeta = () => {
  let location = useLocation();
  const { main } = useMain();

  const [metaTags, setMetaTags] = useState<IMeta>({
    title: "",
    description: "",
    canonicalUrl: window.location.href,
  });

  useEffect(() => {
    if (location.pathname === "/" && main?.meta) {
      setTagsDom(main?.meta);
    } else if (metaTags) {
      setTagsDom(metaTags);
    } else {}
    
  }, [location, main?.meta, metaTags]);

  useEffect(() => {
    if (metaTags?.image) {
      triggerFacebookScraper(window.location.href);
    }
  }, [metaTags?.canonicalUrl, metaTags?.image]);

  const setTagsDom = (meta: IMeta) => {
    const title = meta?.title ? `${meta.title} - ${preTitle}` : preTitle;
    const description = meta?.description || "";
    const metaTitle = meta?.title || "";
    const metaImage = meta?.image==="https://aversiclinic.ge/aversi.jpg"?meta?.image:meta?.image
      ? `${process.env.REACT_APP_API_SERVER_URL}/storage/${meta.image}`
      : "";

    document.title = title;

    updateOrCreateMetaTag("title", metaTitle);
    updateOrCreateMetaTag("description", description);
    updateOrCreateMetaProperty("og:title", metaTitle);
    updateOrCreateMetaProperty("og:description", description);
    updateOrCreateMetaProperty("og:url", meta?.canonicalUrl || window.location.href);
    updateOrCreateMetaProperty("twitter:title", metaTitle);
    updateOrCreateMetaProperty("twitter:description", description);
    updateOrCreateMetaProperty("twitter:url", meta?.canonicalUrl || window.location.href);
    if (metaImage) updateOrCreateMetaProperty("og:image", metaImage);
    updateOrCreateCanonicalLinkTag(
      meta?.canonicalUrl || window.location.href
    );
    updateOrCreateMetaTag(
      "og:url",
      meta?.canonicalUrl || window.location.href
    );
  };

  const updateOrCreateMetaTag = (name: string, content: string) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", name);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute("content", content);
  };

  const updateOrCreateMetaProperty = (property: string, content: string) => {
    let metaTag = document.querySelector(`meta[property="${property}"]`);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("property", property);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute("content", content);
  };

  const updateOrCreateCanonicalLinkTag = (href: string) => {
    let linkTag = document.querySelector('link[rel="canonical"]');
    if (!linkTag) {
      linkTag = document.createElement("link");
      linkTag.setAttribute("rel", "canonical");
      document.head.appendChild(linkTag);
    }
    linkTag.setAttribute("href", href);
  };

  const triggerFacebookScraper = (url: string) => {
    var accessToken = 'EAAbWRX9ZA8TQBOx2ghaTZBbV9HfY57kpjVwaapEMXDIdvjVxIlvP3dWRxKL3rqubyOCrpOPlDRVPcK9FGgPjg5ZCfMrpZCVMoOssmMQyEBT96JD59l4AlWLBZAwlMKcSHlVeKaL3qm7VD7gjYr0XoeDTSuVX6xtXKPM2xQCuX6zdZBmyd9GfbH0nC10AZDZD'; // Replace with your actual access token

    const fbShareLink = url;
    fetch('https://graph.facebook.com', {
      method: 'POST',
      body: JSON.stringify({
        id: fbShareLink,
        scrape: true,
        access_token: accessToken
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error triggering Facebook scraper:', error));
  };

  return {
    setMetaTags,
  };
};
